<template>
  <div :class="`m-3 wrapper  wrapper--${bu}`">
      <h2 class="text-center">Top 20 klanten</h2>
    <Loading v-if="loading" />
    <div class="p-2" v-if="!loading">
      <table :class="`w-100 table--perweek table__border--${bu}`">
        <thead :class="`table__head--${bu}`">
          <tr>
            <th class="table__head--text">Klant</th>
            <th class="table__head--text">Naam</th>
            <th class="table__head--text">Aantal</th>
            <th class="table__head--text">Omzet</th>
            <th class="table__head--text">Winst</th>
            <th class="table__head--text">Commissie</th>
            <th class="table__head--text">Winst %</th>
            <th class="table__head--text">Stadagen</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in top_customers"
            :key="key"
            :class="`table__row--color`"
          >
            <td class="table__cell--default"><router-link :to="{ name: 'klantpagina', params: { id: item.klant } }"
        >{{ item.klant }}
      </router-link></td>
            <td class="table__cell--default">{{ item.naam }}</td>
            <td class="table__cell--default table__data--right">{{ item.aantal }}</td>
            <td class="table__cell--default table__data--right">€ {{ item.omzet }}</td>
            <td class="table__cell--default table__data--right">€ {{ item.winst }}</td>
            <td class="table__cell--default table__data--right">€ {{ item.commisie }}</td>
            <td class="table__cell--default table__data--right">{{ item.winstperc }} %</td>
            <td class="table__cell--default table__data--right">{{ item.stadagen }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";

export default {
  props: ["bu"],
  components: { Loading },
  data: () => ({
    loading: null,
    top_customers: null,
    year_number: null,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.loading = true;
      request(`top-customers/${bu}`, "GET").then(
        ({ top_customers }) => {
          this.top_customers = top_customers;
          this.loading = false;
        }
      );
    },
  },
};
</script>
